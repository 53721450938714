import { useLayoutEffect } from 'react'

import { useSelector } from 'react-redux'

import { selectIsInContextEnabled } from '~/shared/store/common'
import { getCurrentEnvironment } from '~/shared/utils'

export const useInContextInitialization = () => {
  const { isStageEnvironment } = getCurrentEnvironment()
  const IsInContextEnabled = useSelector(selectIsInContextEnabled)

  useLayoutEffect(() => {
    if (!isStageEnvironment || !IsInContextEnabled) return

    const loadInContextScript = async () => {
      const initialScript = document.createElement('script')
      const externalScript = document.createElement('script')

      initialScript.type = 'text/javascript'
      initialScript.text = `
      var _jipt = [];
      _jipt.push(['project', '6e210e5132b0620fc07aca9f61804018']);
      _jipt.push(['domain', 'gismart']);
    `

      externalScript.type = 'text/javascript'
      externalScript.src = '//cdn.crowdin.com/jipt/jipt.js'
      externalScript.async = true

      document.body.appendChild(initialScript)
      document.body.appendChild(externalScript)
    }

    const timeoutId = setTimeout(loadInContextScript, 500)

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeoutId)
  }, [isStageEnvironment, IsInContextEnabled])
}
