import React, { Suspense } from 'react'

import { GrowthBookProvider } from '@growthbook/growthbook-react'
import i18n from 'i18next'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Route, Routes } from 'react-router-dom'
import { polyfill as smoothScrollPolyfill } from 'smoothscroll-polyfill'

import { PrivacyPolicy } from '~/pages/privacyPolicy'
import { TermsOfUse } from '~/pages/termsOfUse'

import favicon from '~/shared/assets/images/favicon.png'
import { Spinner } from '~/shared/components/Spinner'
import { PageId, TOU_AND_PP_LINKS } from '~/shared/constants'
import { useBulkSelector } from '~/shared/hooks/useBulkSelector'
import { PageInfoProvider } from '~/shared/providers/PageInfoProvider'
import { selectConfig, selectCurrentVariant } from '~/shared/store/common'
import { selectUserStatus } from '~/shared/store/user'
import { appHistory, getCurrentEnvironment } from '~/shared/utils'

import { useGoogleAnalytics } from './hooks/useGoogleAnalytics'
import { useHotJarInitialization } from './hooks/useHotJarInitialization'
import { useInContextInitialization } from './hooks/useInContextInitialization'
import { useInitAppConfig } from './hooks/useInitAppConfig'
import { useInitPixels } from './hooks/useInitPixels'
import { useLanguage } from './hooks/useLanguage'
import { useOutbrainPixelInitialization } from './hooks/useOutbrainPixelInitialization'
import { useScrollFix } from './hooks/useScrollFix'
import { useSetUserStatus } from './hooks/useSetUserStatus'
import { useSetUuid } from './hooks/useSetUuid'
import { useSetVariant } from './hooks/useSetVariant'
import { useTaboolaPixelInitialization } from './hooks/useTaboolaPixelInitialization'
import { AppLayout } from './layouts/AppLayout'
import { growthBookInstance } from './lib/growthBook'
import { I18nInterceptorProvider, i18nInterceptor } from './lib/i18n'
import { setViewportFullHeight } from './lib/setViewportFullHeight'
import { CustomRouter } from './router/CustomRouter'

setViewportFullHeight()
smoothScrollPolyfill()

const useSelectors = () =>
  useBulkSelector({
    userStatus: selectUserStatus,
    variant: selectCurrentVariant,
    config: selectConfig,
  })

export const App: React.FC = () => {
  const { userStatus, config, variant } = useSelectors()

  const { isStageEnvironment, isLocalEnvironment, isProdEnvironment } =
    getCurrentEnvironment()

  if (isStageEnvironment || isLocalEnvironment) i18nInterceptor(i18n)

  useSetVariant()
  useInitPixels()
  useInitAppConfig()
  useGoogleAnalytics()
  useSetUuid()
  useSetUserStatus()
  useScrollFix()
  useTaboolaPixelInitialization()
  useOutbrainPixelInitialization()
  useLanguage()
  useInContextInitialization()
  useHotJarInitialization()

  return (
    <HelmetProvider>
      <CustomRouter history={appHistory.get()}>
        <Helmet>
          <link rel="icon" href={favicon} />
        </Helmet>
        {!isProdEnvironment && <meta name="robots" content="noindex" />}
        <Suspense fallback={<Spinner />}>
          {TOU_AND_PP_LINKS.includes(window.location.pathname) ? (
            <Routes>
              <Route path={PageId.TERMS_OF_USE} element={<TermsOfUse />} />
              <Route path={PageId.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            </Routes>
          ) : (
            <GrowthBookProvider growthbook={growthBookInstance}>
              {userStatus && variant && config ? (
                <PageInfoProvider>
                  <I18nInterceptorProvider>
                    <AppLayout />
                  </I18nInterceptorProvider>
                </PageInfoProvider>
              ) : (
                <Spinner />
              )}
            </GrowthBookProvider>
          )}
        </Suspense>
      </CustomRouter>
    </HelmetProvider>
  )
}
