import * as Sentry from '@sentry/react'

export const initSentry = (): void => {
  Sentry.init({
    dsn: 'https://be296a2f8f466fdc2881652bf7cc2031@o1132404.ingest.us.sentry.io/4505719415439360',
    tracesSampleRate: 0.05,
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.5,
    tracePropagationTargets: [
      /^\//,
      /^https:\/\/quiz\.nutrimate\.fit\/landing/,
      /^https:\/\/quiz\.nutrimate\.fit\/hooks/,
      /^https:\/\/quiz\.nutrimate\.fit\/api/,
    ],
    ignoreErrors: [
      'top.GLOBALS',
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      'fb_xd_fragment',
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      'conduitPage',
      'Script error.',
      '_avast_submit',
      'withrealtime/messaging',
      /gtag/,
      /illegal/,
      /^chrome-extension:\/\//,
      't.isRemoteEval is not a function',
      't.getForcedFeatures is not a function',
      'undefined is not an object (evaluating "a.K")',
    ],
    beforeSend(event) {
      if (event.exception) return event
      return null
    },
    autoSessionTracking: false,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [/^https:\/\/quiz\.nutrimate\.fit/],
      }),
    ],
  })
}
