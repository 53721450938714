import { useEffect } from 'react'

import { useGrowthBook } from '@growthbook/growthbook-react'
import { useDispatch } from 'react-redux'

import {
  Cohort,
  PageId,
  SubscriptionListType,
  SubscriptionTags,
  UPSELL_BASE_ID,
} from '~/shared/constants'
import { useBulkSelector } from '~/shared/hooks/useBulkSelector'
import { useExperimentalFeatures } from '~/shared/hooks/useExperimentalFeatures'
import { useVatInfo } from '~/shared/hooks/useHasVatInfo'
import { usePageInfo } from '~/shared/providers/PageInfoProvider'
import {
  getSubscriptionListAction,
  selectCurrentVariantCohortToUse,
  selectSubscriptionList,
  setSubscriptionListAction,
} from '~/shared/store/common'
import {
  selectSubscription,
  setSelectedSubscriptionAction,
} from '~/shared/store/payment'
import { TAppDispatch } from '~/shared/store/types'
import { selectUUID } from '~/shared/store/user'

const useSelectors = () =>
  useBulkSelector({
    selectedSubscription: selectSubscription,
    subscriptions: selectSubscriptionList,
    uuid: selectUUID,
    cohort: selectCurrentVariantCohortToUse,
  })

export const useSubscriptionManagement = () => {
  const growthBook = useGrowthBook()
  const { subscriptions, selectedSubscription, uuid, cohort } = useSelectors()
  const dispatch: TAppDispatch = useDispatch()
  const {
    isCOPage,
    isCOQuestionPage,
    isPurchasePage,
    currentPageId,
    isCheckoutPage,
    currentSubscriptionPageId,
  } = usePageInfo()
  const hasVatInfo = useVatInfo()
  const { isRecurringFirstUpsell } = useExperimentalFeatures()

  const isUpsellPage = currentPageId.includes(UPSELL_BASE_ID)
  const pageWithSubscriptions = [
    isCOPage,
    isCOQuestionPage,
    isUpsellPage,
    isPurchasePage,
  ]

  const currentPageHasSubscriptions =
    currentSubscriptionPageId === currentPageId

  if (cohort === Cohort.MF_SCC) {
    pageWithSubscriptions.push(currentPageHasSubscriptions)
  }

  const isPageWithSubscriptions = pageWithSubscriptions.some(Boolean)
  const areSubscriptionsAvailable =
    isPageWithSubscriptions && growthBook.ready && uuid

  useEffect(() => {
    if (!areSubscriptionsAvailable) return

    dispatch(setSubscriptionListAction([]))

    let tags: SubscriptionTags[] = []
    let subscriptionType = SubscriptionListType.PURCHASE
    const taxTag = hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX

    if (isPurchasePage) tags = [taxTag]

    if (cohort === Cohort.MF_SCC) {
      if (currentPageHasSubscriptions) tags = [SubscriptionTags.NO_TAX]
    }

    if (isCOPage || isCOQuestionPage) {
      tags = [SubscriptionTags.CANCEL_OFFER, taxTag]
    }

    if (currentPageId === PageId.UPSELL_PAYWALL_1) {
      subscriptionType = isRecurringFirstUpsell
        ? SubscriptionListType.UPSELL
        : SubscriptionListType.ONE_TIME
      tags = [SubscriptionTags.NO_TAX, SubscriptionTags.INAPP_FITNESS_SELFCARE]
    }

    if (currentPageId === PageId.UPSELL_PAYWALL_2) {
      subscriptionType = SubscriptionListType.UPSELL
      tags = [
        taxTag,
        SubscriptionTags.UPSELL_LONG,
        SubscriptionTags.CANCEL_OFFER,
      ]
    }

    if (currentPageId === PageId.UPSELL_PAYWALL_3) {
      subscriptionType = SubscriptionListType.ONE_TIME
      tags = [SubscriptionTags.NO_TAX, SubscriptionTags.INAPP_CHANGES_DESSERTS]
    }

    if (currentPageId === PageId.UPSELL_PAYWALL_4) {
      subscriptionType = SubscriptionListType.ONE_TIME
      tags = [SubscriptionTags.NO_TAX, SubscriptionTags.INAPP_FITNESS]
    }

    if (currentPageId === PageId.UPSELL_PAYWALL_5) {
      subscriptionType = SubscriptionListType.ONE_TIME
      tags = [SubscriptionTags.NO_TAX, SubscriptionTags.INAPP_SELFCARE]
    }

    dispatch(getSubscriptionListAction(subscriptionType, tags.join(',')))
  }, [
    currentPageHasSubscriptions,
    cohort,
    isCheckoutPage,
    areSubscriptionsAvailable,
    dispatch,
    isUpsellPage,
    isRecurringFirstUpsell,
    isCOQuestionPage,
    isCOPage,
    isPurchasePage,
    currentPageId,
    hasVatInfo,
  ])

  /**
   * This is necessary to update the data of the selected subscription
   * after requesting subscription data.
   */
  useEffect(() => {
    if (!subscriptions.length) return

    const defaultSubscription = subscriptions.find((s) => s.isDefault)
    const defaultSubscriptionID =
      selectedSubscription?.id || defaultSubscription?.id
    const subscription = subscriptions.find(
      (s) => s.id === defaultSubscriptionID,
    )

    dispatch(setSelectedSubscriptionAction(subscription || subscriptions[0]))
  }, [dispatch, subscriptions, selectedSubscription])
}
