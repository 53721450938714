import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { PLAN_ADDITIONS_MAP, UPSELL_PAYWALL_PAGES } from '~/shared/constants'
import { getPageIdFromPathName } from '~/shared/helpers/getPageIdFromPathName'
import { selectProductId, setPlanAdditionsAction } from '~/shared/store/payment'

export const useSetPlanAdditions = (): void => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const productId = useSelector(selectProductId)

  useEffect(() => {
    const currentPageId = getPageIdFromPathName(pathname)
    const additions = PLAN_ADDITIONS_MAP[currentPageId] || []
    const planAdditions = UPSELL_PAYWALL_PAGES.includes(currentPageId)
      ? [...additions, productId]
      : additions

    if (planAdditions) {
      dispatch(setPlanAdditionsAction(planAdditions))
    }
  }, [dispatch, pathname, productId])
}
