import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { TAppDispatch } from '~/shared/store/types'
import { getUserStatusAction, selectUUID } from '~/shared/store/user'

export const useSetUserStatus = () => {
  const dispatch: TAppDispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  useEffect(() => {
    if (uuid) {
      dispatch(getUserStatusAction(uuid))
    }
  }, [dispatch, uuid])
}
