import React, { useEffect, useMemo } from 'react'

import { useSelector } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'

import { getPathFromPageId } from '~/shared/helpers/getPathFromPageId'
import { useGenderCohortInitialization } from '~/shared/hooks/useGenderCohortInitialization'
import { usePageInfo } from '~/shared/providers/PageInfoProvider'
import {
  selectCurrentVariantCohort,
  selectLanguage,
} from '~/shared/store/common'
import {
  selectTestEnvironmentQueryParam,
  selectUUID,
} from '~/shared/store/user'
import { appHistory } from '~/shared/utils'

export const OnboardingLayout: React.FC = () => {
  const { search } = useLocation()

  const cohort = useSelector(selectCurrentVariantCohort)
  const uuid = useSelector(selectUUID)
  const testEnvironmentQueryParam = useSelector(selectTestEnvironmentQueryParam)
  const language = useSelector(selectLanguage)

  const { steps } = usePageInfo()

  useGenderCohortInitialization()

  const firstPagePath = useMemo(
    () =>
      getPathFromPageId({
        pageId: steps[0].id,
        cohort,
        uuid,
        testEnvironmentQueryParam,
        language,
        currentSearch: search,
      }),
    [steps, cohort, uuid, testEnvironmentQueryParam, search],
  )

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const uuidFromUrl = URLParams.get('uuid')

    if (!uuidFromUrl) {
      appHistory.goTo(firstPagePath)
    }
  }, [search, firstPagePath])

  return <Outlet />
}
