import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { getAppConfigAction } from '~/shared/store/common'
import { TAppDispatch } from '~/shared/store/types'

export const useInitAppConfig = (): void => {
  const dispatch: TAppDispatch = useDispatch()

  useEffect(() => {
    dispatch(getAppConfigAction())
  }, [dispatch])
}
