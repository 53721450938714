import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { Cohort } from '~/shared/constants'
import { getVariantAction } from '~/shared/store/common'
import { TAppDispatch } from '~/shared/store/types'

export const useSetVariant = (): void => {
  const dispatch: TAppDispatch = useDispatch()
  const URLParams = new URLSearchParams(document.location.search)
  const cohortFromUrl = URLParams.get('cohort')

  useEffect(() => {
    dispatch(getVariantAction({ cohort: cohortFromUrl || Cohort.MF_INTRO }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
