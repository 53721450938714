import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { TOU_AND_PP_LINKS } from '~/shared/constants'
import { useBulkSelector } from '~/shared/hooks/useBulkSelector'
import {
  selectCurrentVariantCohort,
  selectGiaApiKeys,
  selectLanguage,
} from '~/shared/store/common'
import { TAppDispatch } from '~/shared/store/types'
import { setUpUUIDAction } from '~/shared/store/user'
import { parseQueryParams } from '~/shared/utils'

const useSelectors = () =>
  useBulkSelector({
    cohort: selectCurrentVariantCohort,
    giaApiKeys: selectGiaApiKeys,
    language: selectLanguage,
  })

export const useSetUuid = () => {
  const dispatch: TAppDispatch = useDispatch()
  const { giaApiKeys, cohort, language } = useSelectors()

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    const uuidFromUrl = searchParams.get('uuid')
    const utmTags = parseQueryParams(document.location.search)
    const isPPOrToUPage = TOU_AND_PP_LINKS.includes(window.location.pathname)

    if (cohort && giaApiKeys && language && !isPPOrToUPage) {
      dispatch(
        setUpUUIDAction({
          cohort,
          uuid: uuidFromUrl,
          utmTags,
          giaApiKey: giaApiKeys[language],
          language,
        }),
      )
    }
  }, [dispatch, cohort, language, giaApiKeys])
}
