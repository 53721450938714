import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { HOT_JAR_CLIENT_ID } from '~/shared/constants'
import { useCookieConsentAnswer } from '~/shared/hooks/useCookieConsentAnswer'
import { selectUserCountryCode } from '~/shared/store/user'
import { getCurrentEnvironment } from '~/shared/utils'

export const useHotJarInitialization = (): void => {
  const { isProdEnvironment } = getCurrentEnvironment()

  const HOTJAR_CLIENT_ID = isProdEnvironment
    ? HOT_JAR_CLIENT_ID.PROD
    : HOT_JAR_CLIENT_ID.DEV

  const userCountryCode = useSelector(selectUserCountryCode)

  const { isEUUser, hasAnalyticsAndPerformanceCookie } =
    useCookieConsentAnswer()

  const hotJarBePaused = isEUUser && !hasAnalyticsAndPerformanceCookie

  useEffect(() => {
    // Hotjar only works over HTTPS.
    if (window.location.protocol !== 'https:') return

    if (hotJarBePaused || !userCountryCode) {
      window.hj = undefined
      return
    }

    if (typeof window.hj === 'function') return

    const loadHotJarScript = async () => {
      const script = document.createElement('script')
      script.async = true
      script.text = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${HOTJAR_CLIENT_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
      document.body.appendChild(script)
    }

    loadHotJarScript()
  }, [HOTJAR_CLIENT_ID, hotJarBePaused, userCountryCode])
}
