import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { i18nFormatters } from '~/shared/utils'

import { loadLanguageResources } from './loadLanguageResources'

i18n
  .use({
    type: 'backend',
    read: async (lng, ns, callback) => {
      try {
        const resource = await loadLanguageResources(lng)
        callback(null, resource[ns])
      } catch (err) {
        callback(err, null)
      }
    },
  })
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  })
  .catch((err) => console.error('i18n initialization failed:', err))

i18n.services.formatter?.add('number', i18nFormatters.number)
i18n.services.formatter?.add('currency', i18nFormatters.currency)
i18n.services.formatter?.add('percent', i18nFormatters.percent)
i18n.services.formatter?.add('datetime', i18nFormatters.datetime)
