import { useEffect, useMemo } from 'react'

import { useSelector } from 'react-redux'

import { TaboolaEventName } from '~/shared/constants'
import { useCookieConsentAnswer } from '~/shared/hooks/useCookieConsentAnswer'
import { selectTaboolaPixelId } from '~/shared/store/common'
import { selectUUID, selectUserCountryCode } from '~/shared/store/user'

export const useTaboolaPixelInitialization = () => {
  const uuid = useSelector(selectUUID)
  const taboolaPixelId = useSelector(selectTaboolaPixelId)
  const countryCode = useSelector(selectUserCountryCode)

  const { isEUUser, hasAdvertisingAndTargetingCookie } =
    useCookieConsentAnswer()

  const shouldTaboolaPixelBePaused = useMemo(
    () => isEUUser && !hasAdvertisingAndTargetingCookie,
    [hasAdvertisingAndTargetingCookie, isEUUser],
  )

  useEffect(() => {
    if (
      !uuid ||
      !countryCode ||
      !taboolaPixelId ||
      shouldTaboolaPixelBePaused
    ) {
      return
    }

    const loadTaboolaScript = async () => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.text = `
    window._tfa = window._tfa || [];
    window._tfa.push({notify: 'event', name: '${TaboolaEventName.PAGE_VIEW}', id: ${taboolaPixelId}});
    !function (t, f, a, x) {
           if (!document.getElementById(x)) {
              t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
           }
    }(document.createElement('script'),
    document.getElementsByTagName('script')[0],
    '//cdn.taboola.com/libtrc/unip/${taboolaPixelId}/tfa.js',
    'tb_tfa_script');
    `
      document.body.appendChild(script)
    }

    loadTaboolaScript()
  }, [countryCode, shouldTaboolaPixelBePaused, taboolaPixelId, uuid])
}
