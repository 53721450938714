import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { TEST_ENV_QUERY_PARAM } from '~/shared/constants'
import { setTestEnvironmentQueryParamAction } from '~/shared/store/user'

export const useTestEnvQueryParameter = (): void => {
  const { search } = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const hasTestEnvQueryParam = URLParams.has(TEST_ENV_QUERY_PARAM)
    const testEnvQueryParamFromUrl = hasTestEnvQueryParam
      ? `&${TEST_ENV_QUERY_PARAM}`
      : ''

    dispatch(setTestEnvironmentQueryParamAction(testEnvQueryParamFromUrl))
  }, [dispatch, search])
}
