import React from 'react'

import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/500.css'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/open-sans/800.css'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { store } from '~/shared/store'
import { getCurrentEnvironment } from '~/shared/utils'

import { App } from './App'
import './lib/i18n/i18n'
import { initSentry } from './lib/sentry'
import './styles.css'

const { isLocalEnvironment, isProdEnvironment } = getCurrentEnvironment()
if (isProdEnvironment) setTimeout(() => initSentry(), 0)

const root = createRoot(document.getElementById('root') as HTMLElement)

const renderApp = () => {
  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  )
}

if (isLocalEnvironment && import.meta.webpackHot) {
  import.meta.webpackHot.accept('./App', () => {
    renderApp()
  })
  console.debug('[HMR] - App updated')
}

renderApp()
