import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { eventLogger } from '~/shared/services'
import { selectUUID } from '~/shared/store/user'

export const useStartSession = (): void => {
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  useEffect(() => {
    eventLogger.logSessionStarted()
  }, [dispatch])

  useEffect(() => {
    if (uuid) {
      window.fbq('track', 'ViewContent', {}, { eventID: uuid })
    }
  }, [uuid])
}
