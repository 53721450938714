import { useCallback, useEffect } from 'react'

import { useBulkSelector } from '~/shared/hooks/useBulkSelector'
import { useCookieConsentAnswer } from '~/shared/hooks/useCookieConsentAnswer'
import { googleAnalyticsLogger } from '~/shared/services'
import {
  selectCookieConsentCountries,
  selectGoogleAdsConversions,
  selectGoogleAdsId,
  selectGoogleAnalyticsId,
} from '~/shared/store/common'
import { selectUserCountryCode } from '~/shared/store/user'

const GA_CONSENT_VALUE = {
  GRANTED: 'granted',
  DENIED: 'denied',
} as const

const useSelectors = () =>
  useBulkSelector({
    googleAnalyticsId: selectGoogleAnalyticsId,
    googleAdsId: selectGoogleAdsId,
    userCountryCode: selectUserCountryCode,
    cookieConsentCountries: selectCookieConsentCountries,
    googleAdsConversions: selectGoogleAdsConversions,
  })

export const useGoogleAnalytics = (): void => {
  const {
    googleAnalyticsId,
    googleAdsId,
    userCountryCode,
    cookieConsentCountries,
    googleAdsConversions,
  } = useSelectors()

  const {
    isEUUser,
    cookieConsent,
    hasAdvertisingAndTargetingCookie,
    hasFunctionalCookie,
    hasAnalyticsAndPerformanceCookie,
  } = useCookieConsentAnswer()

  const setDefaultConsentOptions = useCallback(() => {
    if (!isEUUser) return

    const script = document.createElement('script')
    script.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
    
      gtag('consent', 'default', {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'denied'
      });
    `
    document.body.appendChild(script)
  }, [isEUUser])

  const initGtag = useCallback(() => {
    const script = document.createElement('script')
    script.text = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        `
    script.async = true
    document.body.appendChild(script)
  }, [])

  const setUpGTM = useCallback(() => {
    const script = document.createElement('script')
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
    script.async = true
    document.body.appendChild(script)
  }, [googleAnalyticsId])

  const setUpAdsGTM = useCallback(() => {
    const script = document.createElement('script')
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAdsId}`
    script.async = true
    document.body.appendChild(script)
  }, [googleAdsId])

  useEffect(() => {
    if (!googleAnalyticsId || !userCountryCode) return

    googleAnalyticsLogger.configure(googleAdsConversions)
    setDefaultConsentOptions()
    initGtag()
    setUpGTM()
    setUpAdsGTM()

    window.gtag('config', googleAnalyticsId, {
      send_page_view: false,
    })
  }, [
    googleAdsConversions,
    googleAnalyticsId,
    initGtag,
    setDefaultConsentOptions,
    setUpAdsGTM,
    setUpGTM,
    userCountryCode,
  ])

  useEffect(() => {
    if (!isEUUser || !cookieConsent?.length || !window.gtag) return

    window.gtag('consent', 'update', {
      ad_user_data: hasAdvertisingAndTargetingCookie
        ? GA_CONSENT_VALUE.GRANTED
        : GA_CONSENT_VALUE.DENIED,
      ad_personalization:
        hasAdvertisingAndTargetingCookie && hasFunctionalCookie
          ? GA_CONSENT_VALUE.GRANTED
          : GA_CONSENT_VALUE.DENIED,
      ad_storage:
        hasAdvertisingAndTargetingCookie && hasFunctionalCookie
          ? GA_CONSENT_VALUE.GRANTED
          : GA_CONSENT_VALUE.DENIED,
      analytics_storage: hasAnalyticsAndPerformanceCookie
        ? GA_CONSENT_VALUE.GRANTED
        : GA_CONSENT_VALUE.DENIED,
      region: cookieConsentCountries,
    })
  }, [
    cookieConsent?.length,
    cookieConsentCountries,
    hasAdvertisingAndTargetingCookie,
    hasAnalyticsAndPerformanceCookie,
    hasFunctionalCookie,
    isEUUser,
  ])
}
