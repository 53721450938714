import { SUPPORTED_LOCALES } from '~/shared/constants'

export const loadLanguageResources = async (lng: string) => {
  try {
    const lang = lng.toLowerCase()

    if (SUPPORTED_LOCALES.includes(lang)) {
      return (await import(`~/shared/assets/i18n/${lang}.json`)).default
    }

    return (await import('~/shared/assets/i18n/en.json')).default
  } catch (error) {
    console.error(`Failed to load language ${lng}:`, error)
    throw error
  }
}
