/* eslint-disable max-lines */
import { FC } from 'react'

import { AboutFeelingGoodV1 } from '~/pages/aboutFeelingGood'
import { Accomplish } from '~/pages/accomplish'
import {
  AchieveResultV1,
  AchieveResultV2,
  AchieveResultV3,
  AchieveResultV4,
} from '~/pages/achieveResult'
import {
  ActivityDuringWorkV1,
  ActivityDuringWorkV2,
} from '~/pages/activityDuringWork'
import { ActivityLevelV1, ActivityLevelV2 } from '~/pages/activityLevel'
import { AgeV1, AgeV2, AgeV3, AgeV4 } from '~/pages/age'
import {
  AgeRange,
  AgeRangeV2,
  AgeRangeV3,
  AgeRangeV4,
  KitAgeRangeV1,
  KitAgeRangeV2,
} from '~/pages/ageRange'
import { AiTechV1 } from '~/pages/aiTech'
import {
  AnalyzingAnswersV1,
  AnalyzingAnswersV2,
} from '~/pages/analyzingAnswers'
import { BadHabitsV1, KitBadHabitsV1 } from '~/pages/badHabits'
import { BadHabitsFeedbackV1 } from '~/pages/badHabitsFeedback'
import { BellyTypeV1 } from '~/pages/bellyType'
import { BenefitsV1 } from '~/pages/benefits'
import { BetweenMealsV1 } from '~/pages/betweenMeals'
import {
  BodyTypeV1,
  BodyTypeV2,
  BodyTypeV3,
  BodyTypeV4,
} from '~/pages/bodyType'
import { BreakfastTimeV1 } from '~/pages/breakfastTime'
import { BreathAfterClimbingV1 } from '~/pages/breathAfterClimbing'
import { CaloriesEqualityV1 } from '~/pages/caloriesEquality'
import { CaloriesImpactV1 } from '~/pages/caloriesImpact'
import { KitCancelOfferV1 } from '~/pages/cancelOffer'
import { KitCancelOfferQuestionV1 } from '~/pages/cancelOfferQuestion'
import {
  ChallengesYouFacedV1,
  KitChallengesYouFacedV1,
} from '~/pages/challengesYouFaced'
import { CheckboxQuestion } from '~/pages/checkboxQuestion'
import {
  CheckoutVariant1,
  CheckoutVariant2,
  KitCheckoutV3,
  KitCheckoutV4,
  KitCheckoutVariant1,
} from '~/pages/checkout'
import { ChooseEvent } from '~/pages/chooseEvent'
import KitCommitV1 from '~/pages/commit/kitV1/KitCommitV1'
import {
  ContributingEventsV1,
  ContributingEventsV2,
} from '~/pages/contributingEvents'
import { Cooking } from '~/pages/cooking'
import {
  CreatingPlanV1,
  CreatingPlanV2,
  CreatingPlanV3,
  CreatingPlanV4,
  CreatingPlanV5,
} from '~/pages/creatingPlan'
import {
  CurrentWeightV1,
  CurrentWeightV2,
  CurrentWeightV3,
  CurrentWeightV4,
} from '~/pages/currentWeight'
import {
  DailyWaterV1,
  DailyWaterV2,
  DailyWaterV3,
  KitDailyWaterV1,
} from '~/pages/dailyWater'
import { DateOfBirth } from '~/pages/dateOfBirth'
import { DeterminedV1 } from '~/pages/determined'
import { DietTypeV1 } from '~/pages/dietType'
import DinnerTimeV1 from '~/pages/dinnerTime/v1/DinnerTimeV1'
import DontExerciseV1 from '~/pages/dontExercise/v1/DontExerciseV1'
import { DownloadV1 } from '~/pages/download'
import { DreamBodyV1, DreamBodyV2 } from '~/pages/dreamBody'
import {
  CustomOptionsRightToLeft,
  ImageCenterOptionsBelow,
  ImageOptionsV1,
  ImageWithTextV1,
  InfluencerProfile,
  OptionsLeftToRight,
  OptionsLeftToRightV2,
  OptionsLeftToRightV3,
  OptionsRightToLeft,
  OptionsRightToLeftV3,
} from '~/pages/dynamic'
import { EasyToFollowPlanV1 } from '~/pages/easyToFollowPlan'
import { EatingPlaceV1 } from '~/pages/eatingPlace'
import { Email, EmailV2, EmailV3 } from '~/pages/email'
import {
  EmailConsentV1,
  EmailConsentV2,
  EmailConsentV3,
} from '~/pages/emailConsent'
import { EmotionalEatingV1 } from '~/pages/emotionalEating'
import EnergyValueV1 from '~/pages/energyValue/v1/EnergyValueV1'
import { EventV1, EventV2 } from '~/pages/event'
import { EventDateV1, EventDateV2 } from '~/pages/eventDate'
import {
  EventFeedback,
  EventFeedbackV2,
  EventFeedbackV3,
} from '~/pages/eventFeedback'
import { FastingV1, FastingV2 } from '~/pages/fasting'
import { FastingAtWeekend } from '~/pages/fastingAtWeekend'
import { FastingFeedbackV1, FastingFeedbackV2 } from '~/pages/fastingFeedback'
import { FavouriteFoodsV1 } from '~/pages/favouriteFoods'
import { Feedback, FeedbackV2, FeedbackV3 } from '~/pages/feedback'
import FeelGuiltyV1 from '~/pages/feelGuilty/v1/FeelGuiltyV1'
import { FeelHungryV1 } from '~/pages/feelHungry'
import {
  FeelingsToAchieve,
  FeelingsToAchieveV2,
} from '~/pages/feelingsToAchieve'
import { FinishingTouches } from '~/pages/finishingTouches'
import { GainWeightMenopause } from '~/pages/gainWeightMenopause'
import { GainWeightPeriod } from '~/pages/gainWeightPeriod'
import {
  GenderV1,
  GenderV2,
  GenderV3,
  GenderV4,
  GenderV5,
  GenderV6,
} from '~/pages/gender'
import {
  GoalWeightV1,
  GoalWeightV2,
  GoalWeightV3,
  GoalWeightV4,
} from '~/pages/goalWeight'
import { GoodHandsV1, GoodHandsV2 } from '~/pages/hands'
import { HaveYouHeard } from '~/pages/haveYouHeard'
import { Height, HeightV2, HeightV3, HeightV4 } from '~/pages/height'
import { HelpCalmCravings } from '~/pages/helpCalmCravings'
import { HitYourWeightLossV1 } from '~/pages/hitYourWeightLoss'
import { IdealWeightFeedbackV1 } from '~/pages/idealWeightFeedback'
import {
  IdealWeightTime,
  IdealWeightTimeV2,
  IdealWeightTimeV3,
} from '~/pages/idealWeightTime'
import { ImprovementsV1 } from '~/pages/improvements'
import { KetoDiet } from '~/pages/ketoDiet'
import { KitLifeStyle } from '~/pages/lifeStyle'
import { Login } from '~/pages/login'
import LowerRisk from '~/pages/lowerRisk/LowerRisk'
import { LunchTime } from '~/pages/lunchTime'
import { Management } from '~/pages/management'
import { KitMealsV1, KitMealsV2 } from '~/pages/meals'
import { MealsPerDayV1 } from '~/pages/mealsPerDay'
import { MealsPreparationV1 } from '~/pages/mealsPreparation'
import { MenChoice } from '~/pages/menChoice'
import { Menu } from '~/pages/menu'
import { Motivation, MotivationV2, MotivationV3 } from '~/pages/motivation'
import { MotivationPreview } from '~/pages/motivationPreview'
import { KitNotAloneV1 } from '~/pages/notAlone'
import NutrimateHelp from '~/pages/nutrimateHelp/NutrimateHelp'
import OtherStruggles from '~/pages/otherStruggles/OtherStruggles'
import { PastTries } from '~/pages/pastTries'
import {
  PerfectSolutionV1,
  PerfectSolutionV2,
  PerfectSolutionV3,
} from '~/pages/perfectSolution'
import { Period } from '~/pages/period'
import { PeriodAffectAppetite } from '~/pages/periodAffectAppetite'
import { PersonalizeYourPlan } from '~/pages/personalizeYourPlan'
import { PlanPace } from '~/pages/planPace'
import { PreparePlan, PreparePlanV2 } from '~/pages/preparePlan'
import { PresaleV1, PresaleV2, PresaleV3, PresaleV4 } from '~/pages/presale'
import {
  PrimaryGoalV1,
  PrimaryGoalV2,
  PrimaryGoalV3,
  PrimaryGoalV4,
  PrimaryGoalV5,
} from '~/pages/primaryGoal'
import {
  ProfileSummary,
  ProfileSummaryV2,
  ProfileSummaryV3,
} from '~/pages/profileSummary'
import { ProgramSuite } from '~/pages/programSuite'
import {
  KitPurchaseVariant1,
  KitPurchaseVariant2,
  KitPurchaseVariant3,
  PurchaseV1,
  PurchaseV2,
  PurchaseV3,
  PurchaseV4,
  PurchaseV5,
} from '~/pages/purchase'
import { QuestionnaireV1, QuestionnaireV2 } from '~/pages/questionnaire'
import { RadioQuestion } from '~/pages/radioQuestion'
import { ReachGoals } from '~/pages/reachGoals'
import { ReachIdealWeight, ReachIdealWeightV2 } from '~/pages/reachIdealWeight'
import { ReasonToGiveUp } from '~/pages/reasonToGiveUp'
import { KitResetMetabolismV1 } from '~/pages/resetMetabolism'
import { Restrictions } from '~/pages/restrictions'
import { Result } from '~/pages/result'
import { SecondaryGoal } from '~/pages/secondaryGoal'
import {
  KitSleepingTimeV1,
  SleepingTimeV1,
  SleepingTimeV2,
} from '~/pages/sleepingTime'
import { SleepingTimeFeedback } from '~/pages/sleepingTimeFeedback'
import { SmartAi } from '~/pages/smartAi'
import { Squats } from '~/pages/squats'
import { StartJourney, StartJourneyV2 } from '~/pages/startJourney'
import { StayOnTrackV1 } from '~/pages/stayOnTrack'
import { StickingPlan, StickingPlanV2 } from '~/pages/stickingPlan'
import { StopHunger } from '~/pages/stopHunger'
import { Sugar } from '~/pages/sugar'
import { Support } from '~/pages/support'
import { TargetAreas, TargetAreasV2 } from '~/pages/targetAreas'
import {
  ThankYouForYourTrust,
  ThankYouForYourTrustV2,
} from '~/pages/thankYouForYourTrust'
import { TimeYouFeelHungry } from '~/pages/timeYouFeelHungry'
import { UniquelyTailoredPlan } from '~/pages/uniquelyTailoredPlan'
import {
  UpsellPaywallV1,
  UpsellPaywallV2,
  UpsellPaywallV3,
  UpsellPaywallV4,
  UpsellPaywallV5,
} from '~/pages/upsell'
import {
  UsersChoiceV1,
  UsersChoiceV2,
  UsersChoiceV3,
  UsersChoiceV4,
  UsersChoiceV5,
} from '~/pages/usersChoice'
import Walking from '~/pages/walking/Walking'
import { WeAllGainWeight } from '~/pages/weAllGainWeight'
import { WeightGain } from '~/pages/weightGain'
import { WeightGainResult } from '~/pages/weightGainResult'
import { WeightLossDate, WeightLossDateV2 } from '~/pages/weightLossDate'
import { WhatElse } from '~/pages/whatElse'
import { WorkSchedule } from '~/pages/workSchedule'
import { Workout, WorkoutV2, WorkoutV3 } from '~/pages/workout'
import { YoEffectV1, YoEffectV2 } from '~/pages/yoEffect'
import { YouAreNotAloneV1 } from '~/pages/youAreNotAlone'

import { PageId } from '~/shared/constants'
import { TPageProps } from '~/shared/types'

export const PAGE_ID_MAP: Record<string, FC<TPageProps>> = {
  [PageId.PROGRAM_SUITE_1]: ProgramSuite,
  [PageId.ACCOMPLISH]: Accomplish,
  [PageId.IMPROVEMENTS_1]: ImprovementsV1,
  [PageId.BREAKFAST_TIME_1]: BreakfastTimeV1,
  [PageId.LUNCH_TIME_1]: LunchTime,
  [PageId.DINNER_TIME_1]: DinnerTimeV1,
  [PageId.MENU_1]: Menu,
  [PageId.FAVOURITE_FOODS_1]: FavouriteFoodsV1,
  [PageId.RESTRICTIONS_1]: Restrictions,
  [PageId.WORK_SCHEDULE_1]: WorkSchedule,
  [PageId.ABOUT_FEELING_GOOD]: AboutFeelingGoodV1,
  [PageId.KIT_SUPPORT_1]: Support,
  [PageId.ENERGY_VALUE_1]: EnergyValueV1,
  [PageId.FEEL_GUILTY_1]: FeelGuiltyV1,
  [PageId.STAY_ON_TRACK_1]: StayOnTrackV1,
  [PageId.PERIOD_1]: Period,
  [PageId.PERIOD_AFFECT_APPETITE_1]: PeriodAffectAppetite,
  [PageId.KIT_OTHER_STRUGGLES_1]: OtherStruggles,
  [PageId.HAVE_YOU_HEARD_1]: HaveYouHeard,
  [PageId.BENEFITS_1]: BenefitsV1,
  [PageId.WHAT_ELSE_1]: WhatElse,
  [PageId.PAST_TRIES_1]: PastTries,
  [PageId.KIT_LIFE_STYLE_1]: KitLifeStyle,
  [PageId.WALKING_1]: Walking,
  [PageId.BREATH_AFTER_CLIMBING_1]: BreathAfterClimbingV1,
  [PageId.HOW_MANY_SQUATS_1]: Squats,
  [PageId.HOW_DETERMINED_ARE_YOU_1]: DeterminedV1,
  [PageId.LOWER_RISK_1]: LowerRisk,
  [PageId.BELLY_TYPE_1]: BellyTypeV1,
  [PageId.REASON_TO_GIVE_UP_1]: ReasonToGiveUp,
  [PageId.KIT_DONT_EXERCISE_1]: DontExerciseV1,
  [PageId.KIT_COMMIT]: KitCommitV1,
  [PageId.RESULT_1]: Result,
  [PageId.KIT_RESET_METABOLISM_1]: KitResetMetabolismV1,
  [PageId.MANAGEMENT]: Management,
  [PageId.DATE_OF_BIRTH]: DateOfBirth,
  [PageId.COOKING]: Cooking,
  // THANK_YOU_FOR_YOUR_TRUST
  [PageId.THANK_YOU_FOR_YOUR_TRUST]: ThankYouForYourTrust,
  [PageId.THANK_YOU_FOR_YOUR_TRUST_2]: ThankYouForYourTrustV2,
  // HELP
  [PageId.HELP_CALM_CRAVINGS]: HelpCalmCravings,
  [PageId.NUTRIMATE_HELP]: NutrimateHelp,
  // START_JOURNEY
  [PageId.START_JOURNEY_1]: StartJourney,
  [PageId.START_JOURNEY_2]: StartJourneyV2,
  // DYNAMIC
  kit_dynamic_influencer: InfluencerProfile,
  dynamic_options_left_to_right: OptionsLeftToRight,
  dynamic_options_left_to_right_v2: OptionsLeftToRightV2,
  kit_dynamic_options_left_to_right: OptionsLeftToRightV3,
  kit_dynamic_options_right_to_left: OptionsRightToLeftV3,
  dynamic_options_right_to_left: OptionsRightToLeft,
  dynamic_custom_options_right_to_left: CustomOptionsRightToLeft,
  dynamic_image_center_options_below: ImageCenterOptionsBelow,
  kit_dynamic_image_with_text: ImageWithTextV1,
  kit_dynamic_image_options: ImageOptionsV1,
  // AGE
  [PageId.AGE_1]: AgeV1,
  [PageId.AGE_2]: AgeV2,
  [PageId.AGE_3]: AgeV3,
  [PageId.KIT_AGE_1]: AgeV4,
  // AGE_RANGE
  [PageId.AGE_RANGE_1]: AgeRange,
  [PageId.AGE_RANGE_2]: AgeRangeV2,
  [PageId.AGE_RANGE_3]: AgeRangeV3,
  [PageId.AGE_RANGE_4]: AgeRangeV4,
  [PageId.KIT_AGE_RANGE_1]: KitAgeRangeV1,
  [PageId.KIT_AGE_RANGE_2]: KitAgeRangeV2,
  // GENDER
  [PageId.GENDER_1]: GenderV1,
  [PageId.GENDER_2]: GenderV2,
  [PageId.GENDER_3]: GenderV3,
  [PageId.GENDER_4]: GenderV4,
  [PageId.GENDER_5]: GenderV4,
  [PageId.GENDER_6]: GenderV6,
  [PageId.KIT_GENDER_1]: GenderV5,
  // GOAL
  [PageId.PRIMARY_GOAL_1]: PrimaryGoalV1,
  [PageId.PRIMARY_GOAL_2]: PrimaryGoalV2,
  [PageId.PRIMARY_GOAL_3]: PrimaryGoalV3,
  [PageId.KIT_PRIMARY_GOAL_1]: PrimaryGoalV4,
  [PageId.KIT_PRIMARY_GOAL_2]: PrimaryGoalV5,
  [PageId.KIT_REACH_GOALS_1]: ReachGoals,
  [PageId.SECONDARY_GOAL_1]: SecondaryGoal,
  // BODY_TYPE
  [PageId.BODY_TYPE_1]: BodyTypeV1,
  [PageId.BODY_TYPE_2]: BodyTypeV2,
  [PageId.BODY_TYPE_3]: BodyTypeV3,
  [PageId.KIT_BODY_TYPE_4]: BodyTypeV4,
  // DREAM_BODY
  [PageId.DREAM_BODY_1]: DreamBodyV1,
  [PageId.KIT_DREAM_BODY_1]: DreamBodyV2,
  // HEIGHT
  [PageId.HEIGHT_1]: Height,
  [PageId.HEIGHT_2]: HeightV2,
  [PageId.HEIGHT_3]: HeightV3,
  [PageId.KIT_HEIGHT_1]: HeightV4,
  // GOAL_WEIGHT
  [PageId.GOAL_WEIGHT_1]: GoalWeightV1,
  [PageId.GOAL_WEIGHT_2]: GoalWeightV2,
  [PageId.GOAL_WEIGHT_3]: GoalWeightV3,
  [PageId.KIT_GOAL_WEIGHT_1]: GoalWeightV4,
  // CURRENT_WEIGHT
  [PageId.CURRENT_WEIGHT_1]: CurrentWeightV1,
  [PageId.CURRENT_WEIGHT_2]: CurrentWeightV2,
  [PageId.CURRENT_WEIGHT_3]: CurrentWeightV3,
  [PageId.KIT_CURRENT_WEIGHT_1]: CurrentWeightV4,
  // IDEAL_WEIGHT
  [PageId.IDEAL_WEIGHT_TIME_1]: IdealWeightTime,
  [PageId.IDEAL_WEIGHT_TIME_2]: IdealWeightTimeV2,
  [PageId.IDEAL_WEIGHT_TIME_3]: IdealWeightTime,
  [PageId.KIT_IDEAL_WEIGHT_TIME_1]: IdealWeightTimeV3,
  [PageId.IDEAL_WEIGHT_FEEDBACK_1]: IdealWeightFeedbackV1,
  [PageId.KIT_REACH_IDEAL_WEIGHT_1]: ReachIdealWeight,
  [PageId.KIT_REACH_IDEAL_WEIGHT_2]: ReachIdealWeightV2,
  // WEIGHT_LOSS
  [PageId.KIT_WEIGHT_LOSS_DATE_1]: WeightLossDate,
  [PageId.KIT_WEIGHT_LOSS_DATE_2]: WeightLossDateV2,
  [PageId.KIT_HIT_YOUR_WEIGHT_LOSS_1]: HitYourWeightLossV1,
  // GAIN_WEIGHT
  [PageId.WEIGHT_GAIN_TRIGGERS_1]: WeightGain,
  [PageId.WEIGHT_GAIN_RESULT_1]: WeightGainResult,
  [PageId.GAIN_WEIGHT_MENOPAUSE_1]: GainWeightMenopause,
  [PageId.GAIN_WEIGHT_PERIOD_1]: GainWeightPeriod,
  [PageId.WE_ALL_GAIN_WEIGHT_1]: WeAllGainWeight,
  // HABITS
  [PageId.KIT_BAD_HABITS_1]: KitBadHabitsV1,
  [PageId.BAD_HABITS_1]: BadHabitsV1,
  [PageId.BAD_HABITS_FEEDBACK_1]: BadHabitsFeedbackV1,
  // SLEEPING
  [PageId.KIT_SLEEPING_TIME_1]: KitSleepingTimeV1,
  [PageId.SLEEPING_TIME_1]: SleepingTimeV1,
  [PageId.SLEEPING_TIME_2]: SleepingTimeV2,
  [PageId.SLEEPING_TIME_FEEDBACK_1]: SleepingTimeFeedback,
  // CHALLENGES
  [PageId.KIT_CHALLENGES_YOU_FACED_1]: KitChallengesYouFacedV1,
  [PageId.CHALLENGES_YOU_FACED_1]: ChallengesYouFacedV1,
  // WORKOUT
  [PageId.WORKOUT_1]: Workout,
  [PageId.WORKOUT_2]: WorkoutV2,
  [PageId.KIT_WORKOUT_1]: WorkoutV3,
  // WATER
  [PageId.KIT_DAILY_WATER_1]: KitDailyWaterV1,
  [PageId.DAILY_WATER_1]: DailyWaterV1,
  [PageId.DAILY_WATER_2]: DailyWaterV2,
  [PageId.DAILY_WATER_3]: DailyWaterV3,
  // QUESTIONNAIRE
  [PageId.QUESTIONNAIRE_1]: QuestionnaireV1,
  [PageId.QUESTIONNAIRE_2]: QuestionnaireV1,
  [PageId.QUESTIONNAIRE_3]: QuestionnaireV1,
  [PageId.QUESTIONNAIRE_4]: QuestionnaireV1,
  [PageId.QUESTIONNAIRE_5]: QuestionnaireV1,
  [PageId.QUESTIONNAIRE_6]: QuestionnaireV1,
  [PageId.KIT_QUESTIONNAIRE_1]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_2]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_3]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_4]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_5]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_6]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_7]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_8]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_9]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_10]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_11]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_12]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_13]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_14]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_15]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_16]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_17]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_18]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_19]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_20]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_21]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_22]: QuestionnaireV2,
  [PageId.KIT_QUESTIONNAIRE_23]: QuestionnaireV2,
  // RADIO QUESTIONS
  [PageId.RADIO_QUESTION_1]: RadioQuestion,
  // CHECKBOX QUESTIONS
  [PageId.CHECKBOX_QUESTION_1]: CheckboxQuestion,
  // CHOICE
  [PageId.USERS_CHOICE_1]: UsersChoiceV1,
  [PageId.USERS_CHOICE_2]: UsersChoiceV2,
  [PageId.USERS_CHOICE_3]: UsersChoiceV3,
  [PageId.USERS_CHOICE_4]: UsersChoiceV4,
  [PageId.USERS_CHOICE_5]: UsersChoiceV3,
  [PageId.KIT_USERS_CHOICE_1]: UsersChoiceV5,
  [PageId.MEN_CHOICE_1]: MenChoice,
  // MOTIVATION
  [PageId.MOTIVATION_1]: Motivation,
  [PageId.KIT_MOTIVATION_1]: MotivationV2,
  [PageId.KIT_MOTIVATION_2]: MotivationV3,
  [PageId.KIT_MOTIVATION_PREVIEW]: MotivationPreview,
  // EVENT
  [PageId.EVENT_1]: EventV1,
  [PageId.KIT_EVENT_1]: EventV2,
  [PageId.CONTRIBUTING_EVENTS_1]: ContributingEventsV1,
  [PageId.CONTRIBUTING_EVENTS_2]: ContributingEventsV2,
  [PageId.CHOOSE_EVENT_1]: ChooseEvent,
  [PageId.EVENT_DATE_1]: EventDateV1,
  [PageId.KIT_EVENT_DATE_1]: EventDateV2,
  // EVENT_FEEDBACK
  [PageId.EVENT_FEEDBACK_1]: EventFeedback,
  [PageId.EVENT_FEEDBACK_2]: EventFeedbackV2,
  [PageId.KIT_EVENT_FEEDBACK_1]: EventFeedbackV3,
  // ACHIEVE
  [PageId.FEELINGS_TO_ACHIEVE_1]: FeelingsToAchieve,
  [PageId.KIT_FEELINGS_TO_ACHIEVE_1]: FeelingsToAchieveV2,
  [PageId.KIT_ACHIEVE_RESULT_1]: AchieveResultV4,
  [PageId.ACHIEVE_RESULT_1]: AchieveResultV1,
  [PageId.ACHIEVE_RESULT_2]: AchieveResultV2,
  [PageId.ACHIEVE_RESULT_3]: AchieveResultV3,
  // PERFECT
  [PageId.PERFECT_SOLUTION_1]: PerfectSolutionV1,
  [PageId.PERFECT_SOLUTION_2]: PerfectSolutionV2,
  [PageId.KIT_PERFECT_SOLUTION_1]: PerfectSolutionV3,
  // SUMMARY
  [PageId.PROFILE_SUMMARY_1]: ProfileSummary,
  [PageId.PROFILE_SUMMARY_2]: ProfileSummaryV2,
  [PageId.KIT_PROFILE_SUMMARY_1]: ProfileSummaryV3,
  // HANDS
  [PageId.YOU_ARE_IN_GOOD_HANDS_1]: GoodHandsV1,
  [PageId.YOU_ARE_IN_GOOD_HANDS_2]: GoodHandsV2,
  // ALONE
  [PageId.YOU_ARE_NOT_ALONE_1]: YouAreNotAloneV1,
  [PageId.KIT_NOT_ALONE_1]: KitNotAloneV1,
  // MEALS
  [PageId.KIT_MEALS_1]: KitMealsV1,
  [PageId.KIT_MEALS_2]: KitMealsV2,
  [PageId.MEALS_PREPARATION_1]: MealsPreparationV1,
  [PageId.BETWEEN_MEALS_1]: BetweenMealsV1,
  [PageId.MEALS_PER_DAY_1]: MealsPerDayV1,
  // PLAN
  [PageId.KIT_UNIQUELY_TAILORED_PLAN_1]: UniquelyTailoredPlan,
  [PageId.KIT_EASY_TO_FOLLOW_PLAN_1]: EasyToFollowPlanV1,
  [PageId.KIT_PERSONALIZE_YOUR_PLAN_1]: PersonalizeYourPlan,
  [PageId.KIT_CREATING_PLAN_1]: CreatingPlanV4,
  [PageId.KIT_CREATING_PLAN_2]: CreatingPlanV5,
  [PageId.CREATING_PLAN_1]: CreatingPlanV1,
  [PageId.CREATING_PLAN_2]: CreatingPlanV2,
  [PageId.CREATING_PLAN_3]: CreatingPlanV3,
  [PageId.STICKING_PLAN_1]: StickingPlan,
  [PageId.STICKING_PLAN_3]: StickingPlanV2,
  [PageId.PLAN_PACE_1]: PlanPace,
  [PageId.PREPARE_PLAN_1]: PreparePlan,
  [PageId.PREPARE_PLAN_2]: PreparePlanV2,
  // AI
  [PageId.KIT_SMART_AI_1]: SmartAi,
  [PageId.KIT_AI_TECH_1]: AiTechV1,
  // FASTING
  [PageId.FASTING_AT_WEEKEND_1]: FastingAtWeekend,
  [PageId.FASTING_1]: FastingV1,
  [PageId.KIT_FASTING_1]: FastingV2,
  [PageId.FASTING_FEEDBACK_1]: FastingFeedbackV1,
  [PageId.KIT_FASTING_FEEDBACK_1]: FastingFeedbackV2,
  // CALORIES
  [PageId.CALORIES_IMPACT_1]: CaloriesImpactV1,
  [PageId.CALORIES_EQUALITY_1]: CaloriesEqualityV1,
  // YOYO
  [PageId.YOYO_EFFECT_1]: YoEffectV1,
  [PageId.YOYO_EFFECT_2]: YoEffectV2,
  // ANALYZING
  [PageId.ANALYZING_ANSWERS_1]: AnalyzingAnswersV1,
  [PageId.ANALYZING_ANSWERS_2]: AnalyzingAnswersV2,
  // HUNGRY
  [PageId.FEEL_HUNGRY_1]: FeelHungryV1,
  [PageId.TIME_YOU_FEEL_HUNGRY_1]: TimeYouFeelHungry,
  [PageId.STOP_HUNGER_1]: StopHunger,
  // EATING
  [PageId.EATING_PLACE_1]: EatingPlaceV1,
  [PageId.KIT_EMOTIONAL_EATING_1]: EmotionalEatingV1,
  // ACTIVITY
  [PageId.ACTIVITY_DURING_WORK_1]: ActivityDuringWorkV1,
  [PageId.ACTIVITY_DURING_WORK_2]: ActivityDuringWorkV2,
  [PageId.ACTIVITY_LEVEL_1]: ActivityLevelV1,
  [PageId.ACTIVITY_LEVEL_2]: ActivityLevelV2,
  // TARGET_AREAS
  [PageId.TARGET_AREAS_1]: TargetAreas,
  [PageId.KIT_TARGET_AREAS_1]: TargetAreasV2,
  // FEEDBACKS
  [PageId.FEEDBACKS_1]: Feedback,
  [PageId.KIT_FEEDBACKS_1]: FeedbackV2,
  [PageId.KIT_FEEDBACKS_2]: FeedbackV3,
  // DIET
  [PageId.KIT_SUGAR_1]: Sugar,
  [PageId.KIT_KETO_DIET_1]: KetoDiet,
  [PageId.DIET_TYPE_1]: DietTypeV1,
  // PRESALE
  [PageId.PRESALE_1]: PresaleV1,
  [PageId.PRESALE_2]: PresaleV2,
  [PageId.KIT_PRESALE_1]: PresaleV3,
  [PageId.KIT_PRESALE_2]: PresaleV4,
  // EMAIL
  [PageId.ENTER_EMAIL_1]: Email,
  [PageId.ENTER_EMAIL_2]: EmailV2,
  [PageId.KIT_ENTER_EMAIL_1]: EmailV3,
  [PageId.EMAIL_CONSENT_1]: EmailConsentV1,
  [PageId.KIT_EMAIL_CONSENT_1]: EmailConsentV2,
  [PageId.KIT_EMAIL_CONSENT_2]: EmailConsentV3,
  // PURCHASE
  [PageId.PURCHASE_1]: PurchaseV1,
  [PageId.PURCHASE_3]: PurchaseV2,
  [PageId.PURCHASE_4]: PurchaseV3,
  [PageId.KIT_PURCHASE_EMAIL_1]: PurchaseV4,
  [PageId.KIT_PURCHASE_EMAIL_2]: PurchaseV5,
  [PageId.KIT_PURCHASE_1]: KitPurchaseVariant1,
  [PageId.KIT_PURCHASE_2]: KitPurchaseVariant2,
  [PageId.KIT_PURCHASE_3]: KitPurchaseVariant3,
  // CHECKOUT
  [PageId.CHECKOUT_1]: CheckoutVariant1,
  [PageId.CHECKOUT_2]: CheckoutVariant2,
  [PageId.KIT_CHECKOUT_1]: KitCheckoutVariant1,
  [PageId.KIT_CHECKOUT_3]: KitCheckoutV3,
  [PageId.KIT_CHECKOUT_4]: KitCheckoutV4,
  // CANCEL
  [PageId.KIT_CANCEL_OFFER_1]: KitCancelOfferV1,
  [PageId.KIT_CANCEL_OFFER_QUESTION_1]: KitCancelOfferQuestionV1,
  // UPSELL
  [PageId.UPSELL_PAYWALL_1]: UpsellPaywallV1,
  [PageId.UPSELL_PAYWALL_2]: UpsellPaywallV2,
  /**
   * Don't delete UPSELL_PAYWALL_3, it might come in handy in the future
   * https://gismart.atlassian.net/browse/FAS-1243
   */
  [PageId.UPSELL_PAYWALL_3]: UpsellPaywallV3,
  [PageId.UPSELL_PAYWALL_4]: UpsellPaywallV4,
  [PageId.UPSELL_PAYWALL_5]: UpsellPaywallV5,
  // FINISH
  [PageId.FINISHING_TOUCHES]: FinishingTouches,
  [PageId.LOGIN]: Login,
  [PageId.DOWNLOAD]: DownloadV1,
}
