import { GrowthBook } from '@growthbook/growthbook-react'

export const growthBookInstance = new GrowthBook({
  enableDevMode: true,
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
})

try {
  growthBookInstance.init({ streaming: true })
} catch (error) {
  console.error(`Error during growthBookInstance initialization: ${error}`)
}
