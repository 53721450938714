import React, { lazy, useEffect, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Spinner } from '~/shared/components/Spinner'
import {
  COHORTS_WITH_HEADER_V2,
  Color,
  POST_PAYMENT_PAGES,
  PageId,
  TEST_ENV_QUERY_PARAM,
} from '~/shared/constants'
import { useBulkSelector } from '~/shared/hooks/useBulkSelector'
import { useCookieConsentAnswer } from '~/shared/hooks/useCookieConsentAnswer'
import { usePageInfo } from '~/shared/providers/PageInfoProvider'
import { googleAnalyticsLogger } from '~/shared/services'
import {
  selectCurrentVariantCohort,
  selectFacebookPixelIds,
  selectLanguage,
  selectSnapPixelId,
  selectTiktokPixelId,
} from '~/shared/store/common'
import {
  selectUUID,
  selectUserCountryCode,
  sendFacebookParamsAction,
  sendGoogleAdsParamsAction,
} from '~/shared/store/user'
import { getCurrentEnvironment } from '~/shared/utils'

import { CookieConsent } from '../../components/CookieConsent'
import { Header, HeaderV2, HeaderWithBurger } from '../../components/Header'
import { useAmplitudeInitialization } from '../../hooks/useAmplitudeInitialization'
import { useDynamicDiscount } from '../../hooks/useDynamicDiscount'
import { useFacebookPixelInitialization } from '../../hooks/useFacebookPixelInitialization'
import { useGAPageTracker } from '../../hooks/useGAPageTracker'
import { useGiaFlowTrackerInitialization } from '../../hooks/useGiaFlowTrackerInitialization'
import { useGrowthBookInitialization } from '../../hooks/useGrowthBookInitialization'
import { useScrollToTop } from '../../hooks/useScrollToTop'
import { useSetPlanAdditions } from '../../hooks/useSetPlanAdditions'
import { useSnapPixelInitialization } from '../../hooks/useSnapPixelInitialization'
import { useStartSession } from '../../hooks/useStartSession'
import { useSubscriptionManagement } from '../../hooks/useSubscriptionManagement'
import { useTestEnvQueryParameter } from '../../hooks/useTestEnvQueryParameter'
import { useTiktokPixelInitialization } from '../../hooks/useTiktokPixelInitialization'
import { useUtmTags } from '../../hooks/useUtmTags'
import { growthBookInstance } from '../../lib/growthBook'
import { setI18nInterceptorCallback, useI18nInterceptor } from '../../lib/i18n'
import { RouteList } from '../../router/RouteList'
import { BackgroundGlobalStyles } from './AppLayout.styles'

const InContextPopup = lazy(() =>
  import('../../components/InContextPopup').then((m) => ({
    default: m.InContextPopup,
  })),
)
const LanguageSelector = lazy(() =>
  import('../../components/LanguageSelector').then((m) => ({
    default: m.LanguageSelector,
  })),
)
const TranslationKeysModal = lazy(() =>
  import('../../components/TranslationKeysModal').then((m) => ({
    default: m.TranslationKeysModal,
  })),
)
const PostPaymentProgressBar = lazy(() =>
  import('../../components/PostPaymentProgressBar').then((m) => ({
    default: m.PostPaymentProgressBar,
  })),
)
const OnboardingProgressBar = lazy(() =>
  import('../../components/OnboardingProgressBar').then((m) => ({
    default: m.OnboardingProgressBar,
  })),
)

const POST_PAYMENT_PAGES_WITH_HEADER = [...POST_PAYMENT_PAGES].filter(
  (page) => ![PageId.UPSELL_PAYWALL_4, PageId.UPSELL_PAYWALL_5].includes(page),
)

const useSelectors = () =>
  useBulkSelector({
    uuid: selectUUID,
    cohort: selectCurrentVariantCohort,
    language: selectLanguage,
    facebookPixelIds: selectFacebookPixelIds,
    tiktokPixelId: selectTiktokPixelId,
    snapPixelId: selectSnapPixelId,
    userCountryCode: selectUserCountryCode,
  })

export const AppLayout: React.FC = () => {
  const dispatch = useDispatch()
  const {
    userCountryCode,
    facebookPixelIds,
    snapPixelId,
    tiktokPixelId,
    uuid,
    language,
    cohort,
  } = useSelectors()
  const { search, pathname } = useLocation()
  const { i18n } = useTranslation()
  const { addTranslation } = useI18nInterceptor()
  const { isEUUser, cookieConsent } = useCookieConsentAnswer()
  const {
    currentPageId,
    hasHeader,
    hasBurgerMenu,
    isSecondPage,
    hasProgressbar,
  } = usePageInfo()

  const { isStageEnvironment, isLocalEnvironment } = getCurrentEnvironment()
  const hasTestEnvParam = new URLSearchParams(search).has(TEST_ENV_QUERY_PARAM)
  const isTestEnv = isLocalEnvironment || isStageEnvironment
  const isLanguageSelectorShown = isTestEnv || hasTestEnvParam
  const hasSubscription = useMemo(
    () => POST_PAYMENT_PAGES_WITH_HEADER.includes(currentPageId),
    [currentPageId],
  )
  const isCookieConsentVisible = useMemo(
    () => !cookieConsent && isEUUser,
    [isEUUser, cookieConsent],
  )

  // TODO:this logic will should get rid of HeaderV2 after deactivation cohorts: mf_keto/mf_nosugar/mf_mealplans
  const headerComponent: React.ReactNode = useMemo(() => {
    if (hasBurgerMenu) return <HeaderWithBurger />

    if (COHORTS_WITH_HEADER_V2.includes(cohort)) return <HeaderV2 />

    return <Header />
  }, [cohort, hasBurgerMenu])

  useGrowthBookInitialization()
  useUtmTags()
  useDynamicDiscount()
  useGAPageTracker()
  useFacebookPixelInitialization(facebookPixelIds)
  useTiktokPixelInitialization(tiktokPixelId)
  useSnapPixelInitialization(snapPixelId)
  useGiaFlowTrackerInitialization(cohort)
  useAmplitudeInitialization(cohort)
  useScrollToTop()
  useTestEnvQueryParameter()
  useSetPlanAdditions()
  useStartSession()
  useSubscriptionManagement()

  useEffect(() => {
    growthBookInstance.setAttributes({
      ...growthBookInstance.getAttributes(),
      url: `${pathname}${search}`,
      country: userCountryCode,
      cohort,
      language,
    })
  }, [cohort, language, pathname, search, userCountryCode])

  useEffect(() => {
    if (isSecondPage) {
      dispatch(sendFacebookParamsAction())
      dispatch(sendGoogleAdsParamsAction())
      window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
      googleAnalyticsLogger.logFirstPageCompleted()
    }
  }, [dispatch, isSecondPage, uuid])

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [i18n, language])

  useEffect(() => {
    if (isTestEnv) setI18nInterceptorCallback(addTranslation)
  }, [addTranslation, isTestEnv])

  if (!growthBookInstance?.ready) return <Spinner />

  return (
    <>
      <BackgroundGlobalStyles color={Color.GREY_700} />
      {isStageEnvironment && <InContextPopup />}
      {isLanguageSelectorShown && <LanguageSelector defaultValue={language} />}
      {isTestEnv && <TranslationKeysModal />}
      {hasHeader && headerComponent}
      {/* TODO: delete after deactivating cohorts mf_keto/mf_nosugar/mf_mealplan */}
      {hasProgressbar && COHORTS_WITH_HEADER_V2.includes(cohort) && (
        <OnboardingProgressBar />
      )}
      {hasSubscription && <PostPaymentProgressBar />}
      {isCookieConsentVisible && <CookieConsent />}
      <RouteList />
    </>
  )
}
