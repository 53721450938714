import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  INITIAL_LANGUAGE,
  LANG_QUERY_PARAM,
  Language,
  SUPPORTED_LOCALES,
} from '~/shared/constants'
import {
  selectIsInContextEnabled,
  setLanguageAction,
} from '~/shared/store/common'
import { getCurrentEnvironment } from '~/shared/utils'

export const useLanguage = (): void => {
  const dispatch = useDispatch()
  const IsInContextEnabled = useSelector(selectIsInContextEnabled)
  const { isStageEnvironment } = getCurrentEnvironment()

  const isInContextActive = isStageEnvironment && IsInContextEnabled

  useEffect(() => {
    const langQueryParam =
      new URL(window.location.href).searchParams.get(LANG_QUERY_PARAM) || ''
    const isSupportedLang = SUPPORTED_LOCALES.includes(langQueryParam)
    const langValue = isSupportedLang ? langQueryParam : INITIAL_LANGUAGE
    const newLangValue = isInContextActive ? Language.IN_CONTEXT : langValue

    dispatch(setLanguageAction(newLangValue))
  }, [dispatch, isInContextActive])
}
