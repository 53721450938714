import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { FBConsentValue } from '~/shared/constants'
import { useCookieConsentAnswer } from '~/shared/hooks/useCookieConsentAnswer'
import { selectUUID, selectUserCountryCode } from '~/shared/store/user'

export const useFacebookPixelInitialization = (
  facebookPixelIds: string[],
): void => {
  const uuid = useSelector(selectUUID)
  const userCountryCode = useSelector(selectUserCountryCode)

  const { isEUUser, hasAdvertisingAndTargetingCookie, cookieConsent } =
    useCookieConsentAnswer()

  useEffect(() => {
    if (!window.fbq || !facebookPixelIds.length || !uuid || !userCountryCode) {
      return
    }

    if (!window.facebookPixelInitialized) {
      facebookPixelIds.forEach((facebookPixelId) => {
        window.fbq('init', facebookPixelId, { external_id: uuid })
      })
      window.facebookPixelInitialized = true
    }

    window.fbq('track', 'PageView')
  }, [
    facebookPixelIds,
    hasAdvertisingAndTargetingCookie,
    isEUUser,
    cookieConsent,
    uuid,
    userCountryCode,
  ])

  useEffect(() => {
    if (!window.fbq || !isEUUser) return

    window.fbq(
      'consent',
      hasAdvertisingAndTargetingCookie
        ? FBConsentValue.GRANT
        : FBConsentValue.REVOKE,
    )
  }, [hasAdvertisingAndTargetingCookie, isEUUser])
}
