import { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  EventLoggerInstanceName,
  TEST_ENV_QUERY_PARAM,
} from '~/shared/constants'
import { eventLogger } from '~/shared/services'
import {
  selectGiaApiKeys,
  selectLanguage,
  selectStripeAccountId,
  selectStripeAccountName,
} from '~/shared/store/common'
import { selectUUID, selectUtmTags } from '~/shared/store/user'
import { getCurrentEnvironment } from '~/shared/utils'

import { Amplitude } from '../lib/amplitude'
import { UserFlowTracker } from '../lib/userFlowTracker'

function getGIAAmplitudeOptions() {
  const btoaAuthCredentials = 'cHJveHktdXNlcjozQyk9Q3YncTd5L0A4Pjcp'

  return {
    apiEndpoint: getCurrentEnvironment().isProdEnvironment
      ? 'analytics.gismart.xyz/events'
      : 'analytics.gidev.xyz/events',
    headers: {
      Authorization: `Bearer ${btoaAuthCredentials}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    forceHttps: true,
    disableCookies: true,
  }
}

export const useGiaFlowTrackerInitialization = (cohort = '') => {
  const { search } = useLocation()

  const uuid = useSelector(selectUUID)
  const giaApiKeys = useSelector(selectGiaApiKeys)
  const language = useSelector(selectLanguage)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const utmTags = useSelector(selectUtmTags)

  const [giaInstance, setGiaInstance] = useState<Amplitude | null>(null)

  const URLParams = new URLSearchParams(search)
  const isTestEnvironment = URLParams.has(TEST_ENV_QUERY_PARAM)
  const utmTagsFromUrl = Object.fromEntries(URLParams.entries())
  const { isProdEnvironment } = getCurrentEnvironment()

  useEffect(() => {
    if (uuid && giaApiKeys && language) {
      const giaService = new Amplitude({
        apiKey: giaApiKeys[language],
        instanceName: EventLoggerInstanceName.GIA,
        options: getGIAAmplitudeOptions(),
        userId: uuid,
        utmTags: utmTagsFromUrl.utm_source ? utmTagsFromUrl : utmTags,
        isTestEnvironment,
        isDevEnvironment: !isProdEnvironment,
      })

      const userFlowTracker = new UserFlowTracker({
        instanceName: EventLoggerInstanceName.USER_FLOW_TRACKER,
        userId: uuid,
      })

      giaService.configure({ cohort })
      userFlowTracker.configure({ cohort })
      eventLogger.init(giaService, userFlowTracker)
      setGiaInstance(giaService)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giaApiKeys, uuid, language])

  useEffect(() => {
    if (!stripeAccountId || !stripeAccountName) return

    giaInstance?.updateUserProperties(stripeAccountId, stripeAccountName)
  }, [giaInstance, stripeAccountId, stripeAccountName])
}
