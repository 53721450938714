/* eslint-disable max-lines */
import React, { lazy, useMemo } from 'react'

import { Navigate, useLocation, useRoutes } from 'react-router-dom'

import { CheckoutModal } from '~/pages/checkout'
import { DownloadV1 } from '~/pages/download'
import { FinishingTouches } from '~/pages/finishingTouches'
import { Login } from '~/pages/login'

import { AnimationProvider } from '~/shared/components/AnimationProvider'
import {
  CANCEL_OFFER_BASE_ID,
  CANCEL_OFFER_QUESTION_BASE_ID,
  PUBLIC_PAGES,
  PageId,
  TEN_MINUTES_IN_SECONDS,
  TIMER_SECONDS_MAP,
} from '~/shared/constants'
import { getDynamicPageId } from '~/shared/helpers/getDynamicPageId'
import { getPathFromPageId } from '~/shared/helpers/getPathFromPageId'
import { useBulkSelector } from '~/shared/hooks/useBulkSelector'
import { useExperimentalFeatures } from '~/shared/hooks/useExperimentalFeatures'
import { useUserStatus } from '~/shared/hooks/useUserStatus'
import { usePageInfo } from '~/shared/providers/PageInfoProvider'
import {
  selectCurrentVariantCohort,
  selectLanguage,
} from '~/shared/store/common'
import {
  selectTestEnvironmentQueryParam,
  selectUUID,
} from '~/shared/store/user'
import { IStep } from '~/shared/types'

import { OnboardingLayout } from '../layouts/OnboardingLayout'
import { ProtectedLayout } from '../layouts/ProtectedLayout'
import { PAGE_ID_MAP } from './PageIdMap'

const StripeProvider = lazy(() =>
  import('~/shared/providers/StripeProvider').then((m) => ({
    default: m.StripeProvider,
  })),
)
const TimerProvider = lazy(() =>
  import('~/shared/providers/TimerProvider').then((m) => ({
    default: m.TimerProvider,
  })),
)
const PrimerProvider = lazy(() =>
  import('~/shared/providers/PrimerProvider').then((m) => ({
    default: m.PrimerProvider,
  })),
)

const useSelectors = () =>
  useBulkSelector({
    cohort: selectCurrentVariantCohort,
    uuid: selectUUID,
    testEnvironmentQueryParam: selectTestEnvironmentQueryParam,
    language: selectLanguage,
  })

export const RouteList: React.FC = () => {
  const { search } = useLocation()
  const { cohort, uuid, testEnvironmentQueryParam, language } = useSelectors()
  const availableRoute = useUserStatus()
  const { isCheckoutWithTimer } = useExperimentalFeatures()
  const {
    steps,
    currentSubscriptionPageId,
    currentUpsellPaywallPageId,
    activeUpsellPageIds,
    purchasePageId,
  } = usePageInfo()

  const firstPagePath = useMemo(
    () =>
      getPathFromPageId({
        pageId: steps[0]?.id,
        cohort,
        uuid,
        testEnvironmentQueryParam,
        language,
        currentSearch: search,
      }),
    [steps, cohort, uuid, testEnvironmentQueryParam, language, search],
  )

  const {
    onboardingPages,
    paywallPage,
    checkoutPage,
    cancelOfferQuestionPage,
    cancelOfferPage,
    upsellPages,
  } = useMemo(
    () =>
      steps.reduce<{
        onboardingPages: IStep[]
        paywallPage: IStep | null
        checkoutPage: IStep | null
        cancelOfferQuestionPage?: IStep | null
        cancelOfferPage?: IStep | null
        upsellPages: IStep[]
      }>(
        (accum, { isSubscriptions, isPayment, isUpsell, id }, ind, arr) => {
          const isCOQuestionPageID = id.includes(CANCEL_OFFER_QUESTION_BASE_ID)
          const isCOPageID = id.includes(CANCEL_OFFER_BASE_ID)
          const page = arr[ind]

          if (isSubscriptions && !isCOQuestionPageID && !isCOPageID) {
            accum.paywallPage = page
            return accum
          }

          if (isPayment) {
            accum.checkoutPage = page
            return accum
          }

          if (isCOQuestionPageID) {
            accum.cancelOfferQuestionPage = page
            return accum
          }

          if (isCOPageID) {
            accum.cancelOfferPage = page
            return accum
          }

          if (isUpsell) {
            accum.upsellPages.push(page)
            return accum
          }

          accum.onboardingPages.push(page)
          return accum
        },
        {
          onboardingPages: [],
          paywallPage: null,
          checkoutPage: null,
          cancelOfferQuestionPage: null,
          cancelOfferPage: null,
          upsellPages: [],
        },
      ),
    [steps],
  )

  return useRoutes([
    { index: true, element: <Navigate to={firstPagePath} /> },
    {
      element: <OnboardingLayout />,
      children: onboardingPages.map(({ id: pageId }, index, arr) => {
        const currentPageId = pageId.includes('dynamic')
          ? getDynamicPageId(pageId)
          : pageId
        const CurrentPage = PAGE_ID_MAP[currentPageId]

        const nextPagePath = getPathFromPageId({
          pageId: arr[index + 1]?.id,
          cohort,
          uuid,
          testEnvironmentQueryParam,
          language,
          currentSearch: search,
        })

        const alternativePageId = arr.find(
          (dynamicPage, dynamicPageIndex) =>
            !dynamicPage.isSkippable && dynamicPageIndex > index,
        )?.id

        const alternativePagePath = getPathFromPageId({
          pageId: alternativePageId || arr[index + 1]?.id,
          cohort,
          uuid,
          testEnvironmentQueryParam,
          language,
          currentSearch: search,
        })

        const isFirstPage = index === 0

        if (PUBLIC_PAGES.includes(pageId)) {
          return {
            path: pageId,
            element: (
              <CurrentPage
                isFirstPage={isFirstPage}
                pageId={pageId}
                nextPagePath={nextPagePath}
                alternativePagePath="/"
              />
            ),
          }
        }

        return {
          path: pageId,
          element: (
            <ProtectedLayout isAvailable={!availableRoute}>
              <AnimationProvider>
                <CurrentPage
                  isFirstPage={isFirstPage}
                  pageId={pageId}
                  nextPagePath={nextPagePath}
                  alternativePagePath={alternativePagePath}
                />
              </AnimationProvider>
            </ProtectedLayout>
          ),
        }
      }),
    },
    {
      ...(paywallPage && {
        element: <OnboardingLayout />,
        children: [paywallPage].map(({ id: pageId }, index, arr) => {
          const nextPagePath = getPathFromPageId({
            pageId: checkoutPage?.id || '',
            cohort,
            uuid,
            testEnvironmentQueryParam,
            language,
            currentSearch: search,
          })

          const alternativePageId = arr.find(
            (dynamicPage, dynamicPageIndex) =>
              !dynamicPage.isSkippable && dynamicPageIndex > index,
          )?.id

          const alternativePagePath = getPathFromPageId({
            pageId: alternativePageId || '',
            cohort,
            uuid,
            testEnvironmentQueryParam,
            language,
            currentSearch: search,
          })
          const Page = PAGE_ID_MAP[pageId]

          return {
            path: pageId,
            element: (
              <ProtectedLayout
                isAvailable={availableRoute === currentSubscriptionPageId}
              >
                <TimerProvider
                  id={pageId}
                  time={TIMER_SECONDS_MAP[pageId] || TEN_MINUTES_IN_SECONDS}
                >
                  <CheckoutModal />
                  <Page
                    pageId={pageId}
                    nextPagePath={nextPagePath}
                    alternativePagePath={alternativePagePath}
                  />
                </TimerProvider>
              </ProtectedLayout>
            ),
          }
        }),
      }),
    },
    {
      ...(checkoutPage && {
        element: <OnboardingLayout />,
        children: [checkoutPage]?.map(({ id: pageId }, index, arr) => {
          const nextPagePath = getPathFromPageId({
            pageId: '',
            cohort,
            uuid,
            testEnvironmentQueryParam,
            language,
            currentSearch: search,
          })

          const alternativePageId = arr.find(
            (dynamicPage, dynamicPageIndex) =>
              !dynamicPage.isSkippable && dynamicPageIndex > index,
          )?.id

          const alternativePagePath = getPathFromPageId({
            pageId: alternativePageId || '',
            cohort,
            uuid,
            testEnvironmentQueryParam,
            language,
            currentSearch: search,
          })
          const Page = PAGE_ID_MAP[pageId]

          return {
            path: pageId,
            element: (
              <StripeProvider>
                <PrimerProvider>
                  <ProtectedLayout
                    isAvailable={availableRoute === currentSubscriptionPageId}
                  >
                    <TimerProvider
                      id={purchasePageId as string}
                      time={TIMER_SECONDS_MAP[pageId] || TEN_MINUTES_IN_SECONDS}
                    >
                      <Page
                        pageId={pageId}
                        nextPagePath={nextPagePath}
                        alternativePagePath={alternativePagePath}
                      />
                    </TimerProvider>
                  </ProtectedLayout>
                </PrimerProvider>
              </StripeProvider>
            ),
          }
        }),
      }),
    },
    {
      ...(cancelOfferQuestionPage && {
        element: <OnboardingLayout />,
        children: [cancelOfferQuestionPage]?.map(
          ({ id: pageId }, index, arr) => {
            const nextPagePath = getPathFromPageId({
              pageId: cancelOfferPage?.id || '',
              cohort,
              uuid,
              testEnvironmentQueryParam,
              language,
              currentSearch: search,
            })

            const alternativePageId = arr.find(
              (dynamicPage, dynamicPageIndex) =>
                !dynamicPage.isSkippable && dynamicPageIndex > index,
            )?.id

            const alternativePagePath = getPathFromPageId({
              pageId: alternativePageId || '',
              cohort,
              uuid,
              testEnvironmentQueryParam,
              language,
              currentSearch: search,
            })
            const Page = PAGE_ID_MAP[pageId]

            return {
              path: pageId,
              element: (
                <ProtectedLayout
                  isAvailable={availableRoute === currentSubscriptionPageId}
                >
                  <Page
                    pageId={pageId}
                    nextPagePath={nextPagePath}
                    alternativePagePath={alternativePagePath}
                  />
                </ProtectedLayout>
              ),
            }
          },
        ),
      }),
    },
    {
      ...(cancelOfferPage && {
        element: <OnboardingLayout />,
        children: [cancelOfferPage]?.map(({ id: pageId }, index, arr) => {
          const nextPagePath = getPathFromPageId({
            pageId: '',
            cohort,
            uuid,
            testEnvironmentQueryParam,
            language,
            currentSearch: search,
          })

          const alternativePageId = arr.find(
            (dynamicPage, dynamicPageIndex) =>
              !dynamicPage.isSkippable && dynamicPageIndex > index,
          )?.id

          const alternativePagePath = getPathFromPageId({
            pageId: alternativePageId || '',
            cohort,
            uuid,
            testEnvironmentQueryParam,
            language,
            currentSearch: search,
          })
          const Page = PAGE_ID_MAP[pageId]

          if (isCheckoutWithTimer) {
            return {
              path: pageId,
              element: (
                <TimerProvider
                  id={purchasePageId as string}
                  time={TIMER_SECONDS_MAP[pageId] || TEN_MINUTES_IN_SECONDS}
                >
                  <ProtectedLayout
                    isAvailable={availableRoute === currentSubscriptionPageId}
                  >
                    <>
                      <CheckoutModal />
                      <Page
                        pageId={pageId}
                        nextPagePath={nextPagePath}
                        alternativePagePath={alternativePagePath}
                      />
                    </>
                  </ProtectedLayout>
                </TimerProvider>
              ),
            }
          }

          return {
            path: pageId,
            element: (
              <ProtectedLayout
                isAvailable={availableRoute === currentSubscriptionPageId}
              >
                <>
                  <CheckoutModal />
                  <Page
                    pageId={pageId}
                    nextPagePath={nextPagePath}
                    alternativePagePath={alternativePagePath}
                  />
                </>
              </ProtectedLayout>
            ),
          }
        }),
      }),
    },
    {
      element: <OnboardingLayout />,
      children: upsellPages.map(({ id: pageId }, index, arr) => {
        const UpsellPage = PAGE_ID_MAP[pageId]

        const nextPagePath = arr[index + 1]
          ? getPathFromPageId({
              pageId: arr[index + 1]?.id,
              cohort,
              uuid,
              testEnvironmentQueryParam,
              language,
              currentSearch: search,
            })
          : ''

        const alternativePageId = arr.find(
          (dynamicPage, dynamicPageIndex) =>
            !dynamicPage.isSkippable && dynamicPageIndex > index,
        )?.id

        const alternativePagePath = getPathFromPageId({
          pageId: alternativePageId || arr[index + 1]?.id,
          cohort,
          uuid,
          testEnvironmentQueryParam,
          language,
          currentSearch: search,
        })

        return {
          path: pageId,
          element: (
            <ProtectedLayout
              isAvailable={
                availableRoute === currentUpsellPaywallPageId &&
                activeUpsellPageIds.includes(pageId)
              }
            >
              <UpsellPage
                pageId={pageId}
                nextPagePath={nextPagePath}
                alternativePagePath={alternativePagePath}
              />
            </ProtectedLayout>
          ),
        }
      }),
    },
    {
      path: PageId.FINISHING_TOUCHES,
      element: (
        <ProtectedLayout
          isAvailable={
            availableRoute === currentUpsellPaywallPageId ||
            availableRoute === PageId.FINISHING_TOUCHES
          }
        >
          <AnimationProvider>
            <FinishingTouches />
          </AnimationProvider>
        </ProtectedLayout>
      ),
    },
    {
      path: PageId.LOGIN,
      element: (
        <ProtectedLayout
          isAvailable={
            availableRoute === currentUpsellPaywallPageId ||
            availableRoute === PageId.FINISHING_TOUCHES
          }
        >
          <AnimationProvider>
            <Login />
          </AnimationProvider>
        </ProtectedLayout>
      ),
    },
    {
      path: PageId.DOWNLOAD,
      element: (
        <ProtectedLayout isAvailable={availableRoute === PageId.DOWNLOAD}>
          <AnimationProvider>
            <DownloadV1 />
          </AnimationProvider>
        </ProtectedLayout>
      ),
    },
    { path: '*', element: <Navigate to={firstPagePath} /> },
  ])
}
