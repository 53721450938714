import React, { useState } from 'react'

import { Trans } from 'react-i18next'

import { Logo } from '~/shared/components/Logo'
import { PrivacyPolicyLink } from '~/shared/components/PrivacyPolicyLink'
import { SupportActionLink } from '~/shared/components/SupportActionLink'
import { TermsOfUseLink } from '~/shared/components/TermsOfUseLink'
import { eventLogger } from '~/shared/services'

import { StyledHeaderWithBurger as S } from './Header.styles'

export const HeaderWithBurger: React.FC = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const toggleMenuVisibility = () => setIsMenuVisible((prev) => !prev)

  return (
    <>
      <S.Wrapper>
        <Logo marginBottom={0} />
        <S.HeaderActions>
          <S.Burger type="button" onClick={toggleMenuVisibility}>
            <S.BurgerLine />
            <S.BurgerLine />
            <S.BurgerLine />
          </S.Burger>
        </S.HeaderActions>
      </S.Wrapper>
      <S.Backdrop
        isMenuVisible={isMenuVisible}
        onClick={toggleMenuVisibility}
      />
      <S.Menu isMenuVisible={isMenuVisible}>
        <S.MenuCloseButton type="button" onClick={toggleMenuVisibility} />
        <S.StyledMenuLinkContainer>
          <TermsOfUseLink />
        </S.StyledMenuLinkContainer>
        <S.StyledMenuLinkContainer>
          <PrivacyPolicyLink />
        </S.StyledMenuLinkContainer>
        <S.StyledMenuLinkContainer>
          <SupportActionLink onClick={() => eventLogger.logNeedHelpClicked()}>
            <Trans i18nKey="commonComponents.needHelp" />
          </SupportActionLink>
        </S.StyledMenuLinkContainer>
      </S.Menu>
    </>
  )
}
