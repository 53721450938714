import React, { useMemo } from 'react'

import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

import { getPathFromPageId } from '~/shared/helpers/getPathFromPageId'
import { useUserStatus } from '~/shared/hooks/useUserStatus'
import {
  selectCurrentVariantCohort,
  selectLanguage,
} from '~/shared/store/common'
import {
  selectTestEnvironmentQueryParam,
  selectUUID,
} from '~/shared/store/user'

interface IProps {
  isAvailable: boolean
  children: React.ReactElement
}

export const ProtectedLayout: React.FC<IProps> = ({
  isAvailable,
  children,
}) => {
  const { search } = useLocation()

  const uuid = useSelector(selectUUID)
  const cohort = useSelector(selectCurrentVariantCohort)
  const testEnvironmentQueryParam = useSelector(selectTestEnvironmentQueryParam)
  const language = useSelector(selectLanguage)

  const availableRoute = useUserStatus()

  const pathToRedirect = useMemo(
    () =>
      getPathFromPageId({
        pageId: availableRoute,
        cohort,
        uuid,
        testEnvironmentQueryParam,
        language,
        currentSearch: search,
      }),
    [availableRoute, cohort, uuid, testEnvironmentQueryParam, search],
  )

  return isAvailable ? children : <Navigate to={`/${pathToRedirect}`} />
}
