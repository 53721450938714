import { CookieConsentValue } from '~/shared/constants'

export const enum Source {
  BANNER = 'banner',
  SETTINGS = 'cookies_settings_screen',
}

export const ALL_COOKIE_CONSENT_TYPES = [
  CookieConsentValue.ADVERTISING_AND_TARGETING,
  CookieConsentValue.ANALYTICS_AND_PERFORMANCE,
  CookieConsentValue.FUNCTIONAL_COOKIES,
  CookieConsentValue.STRICTLY_NECESSARY,
]
