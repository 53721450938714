import { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { PagePath } from '~/shared/constants'
import { googleAnalyticsLogger } from '~/shared/services'
import { selectCurrentVariantCohort } from '~/shared/store/common'

export const useGAPageTracker = (): void => {
  const { pathname } = useLocation()
  const cohort = useSelector(selectCurrentVariantCohort)

  useEffect(() => {
    const currentOnboardingVariant = cohort ? `/${cohort}` : ``

    if (window.location.href.includes(PagePath.LOCALHOST)) return

    const currentLocation = `${pathname}${currentOnboardingVariant}`
    googleAnalyticsLogger.logPageView(currentLocation)
  }, [pathname, cohort])
}
