import { useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import { useBulkSelector } from '~/shared/hooks/useBulkSelector'
import { eventLogger } from '~/shared/services'
import { selectGrowthBookExperiments } from '~/shared/store/common'
import { selectUUID } from '~/shared/store/user'

import { growthBookInstance } from '../lib/growthBook'

const useSelectors = () =>
  useBulkSelector({
    uuid: selectUUID,
    growthBookExperiments: selectGrowthBookExperiments,
  })

export const useGrowthBookInitialization = (): void => {
  const { uuid, growthBookExperiments } = useSelectors()
  const location = useLocation()

  useEffect(() => {
    growthBookInstance.setAttributes({ id: uuid })
    growthBookInstance.setURL(window.location.href)
  }, [uuid, location])

  useEffect(() => {
    const unsubscribe = growthBookInstance.subscribe((experiment, result) => {
      if (!growthBookExperiments || !result.hashUsed) return

      const experimentKey = experiment.key || ''
      const variantId = String(result.variationId)

      eventLogger.logGrowthBookAbSegmentName({
        variantId,
        experimentKey,
        variantName: growthBookExperiments?.[experimentKey]?.[variantId],
      })
    })

    return () => unsubscribe()
  }, [growthBookExperiments])
}
