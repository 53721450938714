import dayjs from 'dayjs'

import { eventsApi } from '~/shared/api'
import { EventLoggerInstanceName, Events, PlatformOS } from '~/shared/constants'
import { IEventLogger } from '~/shared/types'
import { getMobileOperatingSystem } from '~/shared/utils'

export class UserFlowTracker implements IEventLogger {
  public readonly name: EventLoggerInstanceName
  private readonly userId: string
  private cohort = ''
  private deviceType: PlatformOS = PlatformOS.UNKNOWN

  constructor({
    userId,
    instanceName,
  }: {
    userId: string
    instanceName: EventLoggerInstanceName
  }) {
    this.userId = userId
    this.name = instanceName
  }

  configure({ cohort }: { cohort: string }): void {
    this.cohort = cohort
    this.deviceType = getMobileOperatingSystem()
  }

  log(
    event: Events,
    eventProperty?: Record<string, unknown>,
    callback?: () => void,
  ): void {
    const timestampInSec = dayjs().unix()

    const mergedEventProperty = {
      cohort: this.cohort,
      user_id: this.userId,
      device_type: this.deviceType,
      timestamp: timestampInSec,
      ...eventProperty,
    }

    eventsApi.sendEvent(event, mergedEventProperty, callback)
  }
}
