import { useEffect, useLayoutEffect, useRef } from 'react'

import { useLocation } from 'react-router-dom'

import {
  EventLoggerInstanceName,
  TEST_ENV_QUERY_PARAM,
} from '~/shared/constants'
import { useBulkSelector } from '~/shared/hooks/useBulkSelector'
import { useCookieConsentAnswer } from '~/shared/hooks/useCookieConsentAnswer'
import { usePageInfo } from '~/shared/providers/PageInfoProvider'
import { eventLogger } from '~/shared/services'
import {
  selectAmplitudeApiKey,
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from '~/shared/store/common'
import { selectIsCheckoutModalShown } from '~/shared/store/payment'
import {
  selectUUID,
  selectUserStatus,
  selectUtmTags,
} from '~/shared/store/user'
import { getCurrentEnvironment } from '~/shared/utils'

import { Amplitude } from '../lib/amplitude'

const useSelectors = () =>
  useBulkSelector({
    uuid: selectUUID,
    amplitudeApiKey: selectAmplitudeApiKey,
    userStatus: selectUserStatus,
    stripeAccountId: selectStripeAccountId,
    stripeAccountName: selectStripeAccountName,
    utmTags: selectUtmTags,
    screenName: selectScreenName,
    isCheckoutModalShown: selectIsCheckoutModalShown,
  })

export const useAmplitudeInitialization = (cohort = '') => {
  const amplitudeInstance = useRef<Amplitude | null>(null)

  const { search } = useLocation()
  const {
    uuid,
    amplitudeApiKey,
    userStatus,
    stripeAccountId,
    stripeAccountName,
    utmTags,
    screenName,
    isCheckoutModalShown,
  } = useSelectors()
  const { hasAnalyticsAndPerformanceCookie, isEUUser } =
    useCookieConsentAnswer()
  const { isCheckoutPage } = usePageInfo()

  const isCheckout = isCheckoutPage || (!isCheckoutPage && isCheckoutModalShown)
  const URLParams = new URLSearchParams(search)
  const isTestEnvironment = URLParams.has(TEST_ENV_QUERY_PARAM)
  const utmTagsFromUrl = Object.fromEntries(URLParams.entries())
  const { isProdEnvironment } = getCurrentEnvironment()

  const shouldAmplitudeBePaused = isEUUser && !hasAnalyticsAndPerformanceCookie
  const isAmplitudeAvailable =
    uuid && amplitudeApiKey && (userStatus?.hasSubscription || isCheckout)

  useLayoutEffect(() => {
    if (eventLogger.isAmplitudeActive || shouldAmplitudeBePaused) return

    if (isAmplitudeAvailable) {
      const amplitudeService = new Amplitude({
        apiKey: amplitudeApiKey,
        instanceName: EventLoggerInstanceName.AMPLITUDE,
        userId: uuid,
        utmTags: utmTagsFromUrl.utm_source ? utmTagsFromUrl : utmTags,
        isTestEnvironment,
        isDevEnvironment: !isProdEnvironment,
      })

      amplitudeService.configure({ cohort })
      eventLogger.init(amplitudeService)
      amplitudeInstance.current = amplitudeService
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    uuid,
    amplitudeApiKey,
    userStatus?.hasSubscription,
    screenName,
    utmTags,
    utmTagsFromUrl,
    isTestEnvironment,
    isProdEnvironment,
    cohort,
    shouldAmplitudeBePaused,
  ])

  useEffect(() => {
    if (!stripeAccountId || !stripeAccountName) return

    if (amplitudeInstance.current) {
      amplitudeInstance.current.updateUserProperties(
        stripeAccountId,
        stripeAccountName,
      )
    }
  }, [amplitudeInstance, stripeAccountId, stripeAccountName])
}
